<template>
    <div>
        <div class="banner-wrapper relative w-screen flex flex-row bg-orange-gradient">
            <AppHeader :isTransparent="headerTransparent" />

            <AppSidebar class="lg:hidden" :isOpen="false" />

            <div class="absolute hidden lg:flex w-full pointer-events-none">
                <div class="lg:w-11/12 xl:w-10/12 mx-auto">
                    <globeSvg class="globe-svg h-48 relative left-1/2 transform -translate-y-16 -translate-x-full opacity-0" />
                </div>
            </div>
            <div class="w-screen self-center flex -mt-10 sm:-mt-32 md:-mt-48 lg:-mt-24">
                <div class="relative w-full sm:w-10/12 lg:w-11/12 xl:w-10/12 mx-auto flex flex-col items-center">
                    <div :ref="'bannerContent'" class="px-4 md:w-3/4 lg:w-1/2">
                        <div class="text-center">
                            <h1 class="text-white text-3xl mb-4 font-semibold">Developer Documentation</h1>
                            <p class="text-white">Explore our Tutorials, Code Samples, API Reference, SDKs and other resources to help you start building with Africa's Talking.</p>
                        </div>
                        <div class="relative hidden sm:block mt-16">
                            <AppSearch :placement="'landing'" />
                            <cylinderSvg class="absolute h-16 -top-6 -right-6 transform rotate-90" />
                        </div>
                    </div>
                    <div class="absolute hidden lg:block h-full w-1/4 self-start">
                        <thunderColoredSvg class="thunder-left-svg absolute h-48 left-0 top-0 transform scale-90 translate-y-10 translate-x-4 opacity-0" />
                        <triangleSvg class="triangle-left-svg absolute h-16 -right-16 -bottom-32 transform translate-y-24 translate-x-32 opacity-0" />
                    </div>
                    <div class="absolute hidden lg:block h-full w-1/4 self-end">
                        <triangleSvg class="triangle-right-svg absolute h-40 right-0 -top-16 transform scale-90 translate-y-20 -translate-x-4 opacity-0" />
                        <thunderColoredSvg class="thunder-right-svg absolute h-24 right-0 -bottom-10 transform translate-y-20 -translate-x-20 opacity-0" />
                    </div>
                </div>
                <div class="banner-footer-elements absolute w-full z-0">
                    <div class="absolute flex w-full pointer-events-none">
                        <div class="w-full sm:w-10/12 md:w-10/12 lg:w-11/12 xl:w-10/12 relative mx-auto flex flex-row justify-between px-4">
                            <codeCubeSvg class="code-cube-svg h-16 lg:h-32 transform lg:translate-x-48 relative z-20" />
                            <cubeSvg class="cube-svg hidden lg:block md:h-48 transform -translate-x-64 -translate-y-1 opacity-0 relative z-0" />
                        </div>
                    </div>
                    <baseCurveSvg class="w-full lg:hidden fill-current text-white relative z-10" />
                    <baseCurveMdSvg class="w-full hidden lg:block fill-current text-white relative z-10" />
                </div>
            </div>
        </div>
        <Products />
        <Spotlight />
        <div class="footer-wrapper relative w-screen flex flex-col justify-end bg-gray-200 mt-40 md:mt-64">
            <div class="absolute flex -top-16 md:-top-32 lg:-top-40 w-full">
                <div class="w-full sm:w-10/12 md:w-11/12 xl:w-10/12 relative mx-auto z-10 px-4 flex flex-row justify-between">
                    <codeCubeSvg class="footer-cube h-16 md:h-32 transform translate-y-16 md:translate-y-32 lg:translate-x-10" />
                    <ninjaTwoSvg class="footer-ninja h-40 md:h-64 lg:h-72 transform -translate-y-6 md:-translate-y-12" />
                </div>
            </div>
            <div class="footer-curve-wrapper absolute -top-12 md:-top-20 w-full z-0 transform rotate-180">
                <baseCurveSvg class="w-full md:hidden fill-current text-white" />
                <baseCurveMdSvg class="w-full hidden md:block fill-current text-white" />
            </div>
            <div class="w-full sm:w-10/12 lg:w-11/12 xl:w-10/12 relative z-10 mx-auto mb-20 md:mb-32">
                <div class="text-center">
                    <h3 class="text-2xl mb-6 lg:mb-10 text-gray-500 font-semibold">Resources</h3>
                </div>
                <div class="relative top-6 md:top-0 flex flex-wrap lg:flex-no-wrap px-6 flex-row lg:justify-center">
                    <div
                        v-for="(resource, idx) in footerResources"
                        :key="idx"
                        class="w-full md:w-auto md:flex-auto lg:flex-none h-auto py-2 md:h-14 mb-5 lg:mb-0 lg:h-48 lg:w-48 bg-white rounded-lg shadow-xl lg:hover:shadow-2xl"
                        :class="{
                            'md:mr-10': idx !== footerResources.length - 1,
                        }">
                        <template v-if="resource.useRouterLink">
                            <router-link
                                :to="resource.link"
                                class="h-full flex flex-row md:flex-col justify-left px-4 md:justify-center items-center">
                                <img class="block h-8 md:h-12 lg:h-20 mr-4 md:mr-0 md:mb-4" :src="resource.img">
                                <span class="lg:text-lg text-gray-500">{{ resource.title }}</span>
                            </router-link>
                        </template>
                        <template v-else>
                            <a
                                :href="resource.link"
                                target="_blank"
                                class="h-full flex flex-row md:flex-col justify-left px-4 md:justify-center items-center">
                                <img class="block h-8 md:h-12 lg:h-20 mr-4 md:mr-0 md:mb-4" :src="resource.img">
                                <span class="lg:text-lg text-gray-500">{{ resource.title }}</span>
                            </a>
                        </template>
                    </div>
                </div>
                <!-- <thunderWhiteSvg class="h-12 hidden absolute left-1/2 transform -translate-x-1/2 translate-y-8"/> -->
            </div>
            <div class="absolute bottom-10 md:bottom-16 w-screen overflow-x-hidden">
                <patternBarSvg class="relative h-6 -left-4 md:left-0 md:h-auto md:w-full fill-current text-gray-400" />
            </div>
            <div class="relative w-screen bg-blue-900 text-white text-center px-4 py-2 md:py-4">
                <span class="text-lg md:text-2xl">We love nerds</span>
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable global-require */

import AppHeader from '@/components/ui/AppHeader.vue';
import AppSearch from '@/components/ui/AppSearch.vue';

import baseCurveSvg from '@/assets/img/baseCurve.svg';
import baseCurveMdSvg from '@/assets/img/baseCurveMd.svg';

import codeCubeSvg from '@/components/landing/elements/codeCubeSvg.vue';
import cubeSvg from '@/components/landing/elements/cubeSvg.vue';
import cylinderSvg from '@/components/landing/elements/cylinderSvg.vue';
import globeSvg from '@/components/landing/elements/globeSvg.vue';
import ninjaTwoSvg from '@/components/landing/elements/ninjaTwotSvg.vue';
// import thunderWhiteSvg from '@/components/landing/elements/thunderWhiteSvg.vue';
import thunderColoredSvg from '@/components/landing/elements/thunderColoredSvg.vue';
import triangleSvg from '@/components/landing/elements/triangleSvg.vue';

import Products from '@/components/landing/Products.vue';
import Spotlight from '@/components/landing/Spotlight.vue';

import animations from './animations';

export default {
    name: 'Home',

    mixins: [animations],

    data() {
        return {
            headerTransparent: true,
            intersectionObserver: null,
            footerResources: [
                {
                    link: '/simulator',
                    img: require('@/assets/img/simulator.png'),
                    title: 'Simulator',
                    useRouterLink: true,
                },
                {
                    link: '/sdks',
                    img: require('@/assets/img/sdks.png'),
                    title: 'SDKs',
                    useRouterLink: true,
                },
                {
                    link: 'https://www.postman.com/africastalking',
                    img: require('@/assets/img/postman.png'),
                    title: 'Postman Collection',
                },
                {
                    link: 'https://help.africastalking.com',
                    img: require('@/assets/img/helpCenter.png'),
                    title: 'Help Center',
                },
                {
                    link: 'https://slack.africastalking.com',
                    img: require('@/assets/img/slack.png'),
                    title: 'Slack',
                },
                {
                    link: 'https://github.com/AfricasTalkingLtd',
                    img: require('@/assets/img/github.png'),
                    title: 'Github',
                },
            ],
        };
    },

    mounted() {
        this.loadLandingScrollObserver();
        this.playAnimations();
    },

    beforeDestroy() {
        this.cancelLandingScrollObserver();
    },

    methods: {
        loadLandingScrollObserver() {
            const options = {
                rootMargin: '-100px',
                threshold: [0, 0.25, 0.5, 0.75],
            };

            const callback = (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        if (entry.intersectionRatio >= 0.5) {
                            this.headerTransparent = true;
                        } else {
                            this.headerTransparent = false;
                        }
                    }
                });
            };

            this.intersectionObserver = new IntersectionObserver(callback, options);
            if (this.$refs.bannerContent) {
                this.intersectionObserver.observe(this.$refs.bannerContent);
            }
        },

        cancelLandingScrollObserver() {
            if (this.intersectionObserver) {
                if (this.$refs.bannerContent) {
                    this.intersectionObserver.unobserve(this.$refs.bannerContent);
                }
                this.intersectionObserver = null;
            }
        },
    },

    components: {
        AppHeader,
        AppSearch,
        baseCurveSvg,
        baseCurveMdSvg,
        codeCubeSvg,
        cubeSvg,
        cylinderSvg,
        globeSvg,
        ninjaTwoSvg,
        thunderColoredSvg,
        triangleSvg,
        Products,
        Spotlight,
        AppSidebar: () => import('@/components/ui/AppSidebar.vue'),
        patternBarSvg: () => import('@/assets/img/patternBar.svg'),
    },
};
</script>

<style lang="postcss" scoped>
.banner-wrapper {
    height: 38rem;
}

.banner-footer-elements {
    bottom: -0.5rem;
    /* height: 38rem; */
}

.footer-wrapper {
    height: 36rem;
}

@screen sm {
    .banner-wrapper {
        height: 40rem;
    }

    .banner-footer-elements {
        bottom: -4rem;
    }

    .footer-curve-wrapper {
        top: -8rem;
    }
}

@screen md {
    .banner-wrapper {
        height: 46rem;
    }

    .banner-footer-elements {
        bottom: -4rem;
    }

    .code-cube-svg {
        height: 7rem;
    }

    .cube-svg {
        height: 11rem;
        --transform-translate-x: -18rem;
        right: 2rem;
        top: -3rem;
    }

    .footer-cube {
        height: 6rem;
        --transform-translate-y: 9rem;
    }

    .footer-ninja {
        height: 16rem;
        --transform-translate-y: -0.5rem;
    }

    .footer-wrapper {
        height: 32rem;
    }

    .footer-curve-wrapper {
        top: -1rem;
    }
}

@screen lg {
    .banner-wrapper {
        height: 43rem;
    }

    .banner-footer-elements {
        bottom: -4rem;
    }

    .thunder-left-svg {
        left: 0;
    }

    .thunder-right-svg {
        right: 0;
    }

    .triangle-right-svg {
        height: 10rem;
        right: 0;
    }

    .code-cube-svg {
        height: 8rem;
        --transform-translate-x: 14rem;
        left: 0;
    }

    .footer-cube {
        height: 7rem;
        --transform-translate-y: 9rem;
    }

    .footer-ninja {
        height: 18rem;
        --transform-translate-y: -1.5rem;
    }

    .footer-wrapper {
        height: 36rem;
    }

    .footer-curve-wrapper {
        top: -4rem;
    }
}

@screen xl {
    .banner-wrapper {
        height: 48rem;
    }

    .banner-footer-elements {
        bottom: -5rem;
    }

    .thunder-left-svg {
        height: 12rem;
        left: 0;
    }

    .triangle-left-svg {
        height: 4rem;
    }

    .thunder-right-svg {
        right: 0;
    }

    .triangle-right-svg {
        height: 10rem;
        right: 0;
    }

    .code-cube-svg {
        height: 8rem;
        --transform-translate-x: 14rem;
        left: 0;
    }

    .cube-svg {
        height: 12rem;
        --transform-translate-x: -18rem;
        right: 0;
        top: -2rem;
    }

    .footer-cube {
        height: 8rem;
        --transform-translate-y: 8rem;
    }

    .footer-ninja {
        height: 18rem;
        --transform-translate-y: -1.5rem;
    }

    .footer-wrapper {
        height: 37rem;
    }

    .footer-curve-wrapper {
        top: -5rem;
    }
}
</style>
